import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconLookup } from '../utilities/iconLookup';

interface LinkStyleProps {
	colorVariant?: 'gray' | 'primary';
	hoverColor?: string;
	isFullwidth?: boolean;
	isLarge?: boolean;
	isMedium?: boolean;
	showArrowIcon?: boolean;
	isBorder?: boolean;
	isUnderline?: boolean;
	isInline?: boolean;
	className?: string;
}

const getClass = (modifiers: LinkStyleProps) => {
	let twTextColor = 'white';
	let twTextHoverColor = 'hover:text-sky-500';
	let twBorderColor = 'white';
	if (modifiers.colorVariant === 'gray') {
		twTextColor = 'text-gray-700';
		twTextHoverColor = 'hover:text-sky-500';
		twBorderColor = 'border-gray-700';
	} else if (modifiers.colorVariant === 'primary') {
		twTextColor = 'text-blue-600';
		twTextHoverColor = 'hover:text-sky-500';
		twBorderColor = 'border-blue-600';
	}
	return (
		'no-underline ' +
		(modifiers.isFullwidth ? `lg:w-full ` : '') +
		(modifiers.isLarge ? `text-base md:text-2xl ` : '') +
		(modifiers.isMedium ? `text-base md:text-xl ` : '') +
		`${twTextColor} ${twTextHoverColor} ` +
		(modifiers.isUnderline ? 'hover:underline ' : '') +
		(modifiers.isBorder ? `py-2 px-3 border-2 ${twBorderColor} rounded-lg` : '')
	);
};

export const InternalLink = ({
	link,
	children,
	showArrowIcon = false,
	className = '',
	...modifiers
}: {
	link: string;
	children;
	showArrowIcon?: boolean;
} & LinkStyleProps) => {
	return (
		<Link
			className={`${className} ${getClass({ colorVariant: 'gray', ...modifiers })}`}
			to={link}
		>
			{children}
			{showArrowIcon && <ArrowIcon />}
		</Link>
	);
};

export const ExternalLink = ({
	link,
	children,
	showArrowIcon = false,
	isNewTab,
	className = '',
	...modifiers
}: {
	link: string;
	children;
	isNewTab?: boolean;
	showArrowIcon?: boolean;
} & LinkStyleProps) => {
	return (
		<a
			target={isNewTab ? '_blank' : ''}
			className={`${className} ${getClass({ colorVariant: 'gray', ...modifiers })}`}
			href={link}
		>
			{children}
			{showArrowIcon && <ArrowIcon />}
		</a>
	);
};

const ArrowIcon = () => {
	return (
		<span className="mx-3">
			<FontAwesomeIcon icon={iconLookup.faArrowRight} size="sm" />
		</span>
	);
};
