import React from 'react';
import { BlogNav } from './BlogNav';

export const BlogLayout = ({
	currentCategory,
	children
}: {
	currentCategory;
	children: JSX.Element | JSX.Element[];
}) => {
	return (
		<section className="flex flex-wrap justify-center w-full px-4 mx-auto mt-24 mb-8 xxl:w-2/3">
			{/* <BlogNav currentCategory={currentCategory} /> */}

			<div className="w-full px-6 lg:w-4/5">{children}</div>
		</section>
	);
};
