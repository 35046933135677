import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { sendAnalyticsEvent } from '../utilities/analyticsFunctions';
import { iconLookup } from '../utilities/iconLookup';

import { IconButton } from './Button';
import { RegisterFormFake } from './Forms/SignupForm';
import { TrialSignupAxios } from './Forms/TrialSignupAxios';

interface RegisterButtonProps {
	label: string;
	onClick: () => void;
	eventLocation: string;
	textColor?: string;
	hoverBackgroundColor?: string;
	isTextButton?: boolean;
	isSmall?: boolean;
	showArrow?: boolean;
	showCreditCardText?: boolean;
	isFullWidth?: boolean;
}

export const registerUrl = 'https://app.embarkable.io/register';

export const RegisterButtonLink = ({
	...restRegisterButtonProps
}: Omit<RegisterButtonProps, 'onClick'>) => {
	// Temporarily use a fake sign up
	return <FakeRegisterButtonAndModal {...restRegisterButtonProps} />;
	// This was the original, a link to the app to sign up
	// return (
	// 	<RegisterButton
	// 		onClick={() => {
	// 			window.location.href = registerUrl;
	// 		}}
	// 		{...restRegisterButtonProps}
	// 	/>
	// );
};

const RegisterButton = ({
	label,
	onClick,
	eventLocation,
	textColor = 'white',
	hoverBackgroundColor = 'red',
	isTextButton = false,
	isSmall = false,
	showArrow = false,
	showCreditCardText = false,
	isFullWidth = false
}: RegisterButtonProps) => {
	const onClickWithEvent = () => {
		sendAnalyticsEvent(`registerClick_${eventLocation}`); //do this before onclick because onClick might be a redirect event
		onClick();
	};
	return (
		<div className="flex flex-col">
			{isTextButton ? (
				<button
					className={`text-${textColor} bg-none transition-colors-shadow duration-500 
					${isSmall ? 'py-2 text-sm md:text-md' : 'py-3 text-lg md:text-xl'} rounded-lg
					${isFullWidth ? 'w-full' : ''}
					focus:outline-0 
					hover:text-${hoverBackgroundColor}-500`}
					onClick={onClickWithEvent}
				>
					{label}
					{showArrow && (
						<span className="mx-3">
							<FontAwesomeIcon icon={iconLookup.faArrowRight} size="sm" />
						</span>
					)}
				</button>
			) : (
				<button
					className={`brand-bg px-4 md:px-8 text-${textColor} max-w-sm mx-auto transition-colors-shadow duration-500 
					rounded-lg shadow-xl ${
						isSmall ? 'py-2 text-sm md:text-lg' : 'py-3 text-lg md:text-xl'
					} focus:outline-0 
					${isFullWidth ? 'w-full' : ''}
					hover:bg-${hoverBackgroundColor}-500 hover:bg-opacity-100`}
					onClick={onClickWithEvent}
				>
					{label}
				</button>
			)}
			{showCreditCardText && (
				<span className="flex justify-center mt-4 text-xs text-gray-500">
					NO CREDIT CARD REQUIRED
				</span>
			)}
		</div>
	);
};

export function FakeRegisterButtonAndModal({
	...registerButtonProps
}: Omit<RegisterButtonProps, 'onClick'>) {
	const [isOpen, toggleOpen] = useState(false);

	return (
		<div>
			<RegisterButton
				{...registerButtonProps}
				onClick={() => {
					toggleOpen(true);
				}}
			/>

			<Modal
				isOpen={isOpen}
				/* style={modalStyles} */
				closeTimeoutMS={300}
				ariaHideApp={false}
				className="ReactModal_Content"
				overlayClassName="ReactModal__Overlay"
			>
				<div className="py-12 px-4 lg:px-12">
					<div className="flex justify-end absolute top-6 right-6">
						<IconButton
							icon={'faTimes'}
							color="text-slate-400"
							hoverColor="text-sky-600"
							onClick={() => toggleOpen(false)}
						/>
					</div>

					<RegisterFormFake />
				</div>
			</Modal>
		</div>
	);
}

export function SubscribeButtonAndModal({
	data,
	...registerButtonProps
}: Omit<RegisterButtonProps, 'onClick'> & { data }) {
	const [isOpen, toggleOpen] = useState(false);

	const bgcolor1 = 'purple-700';
	const bgcolor2 = 'orange-200';
	const bgcolor3 = 'pink-600';

	return (
		<div>
			<RegisterButton
				{...registerButtonProps}
				onClick={() => {
					toggleOpen(true);
				}}
			/>

			<Modal isOpen={isOpen} style={modalStyles} closeTimeoutMS={300} ariaHideApp={false}>
				<div
					className={`w-screen h-screen md:h-160 lg:overflow-hidden md:max-w-6xl p-4 m-0 bg-gradient-to-br from-${bgcolor1} via-${bgcolor3} to-${bgcolor2}`}
				>
					<IconButton right onClick={() => toggleOpen(false)} />

					<div className="flex flex-col w-full lg:flex-row">
						<div className="w-full mx-auto">
							<TrialSignupAxios
								color="indigo"
								inputText={data.inputText}
								buttonText={data.buttonText}
								event_id={registerButtonProps.eventLocation}
								onSubscribe={toggleOpen}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

const modalStyles: Modal.Styles = {
	content: {
		position: 'fixed',
		overflow: 'hidden',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-20%',
		transform: 'translate(-50%, -50%)',
		padding: 'none',
		borderRadius: '10px',
		zIndex: '1000',
		border: 'none'
	},
	overlay: {
		zIndex: '1000',
		position: 'fixed',
		overflow: 'hidden',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		backgroundColor: 'rgba(255, 255, 255, 0.75)',
		backdropFilter: 'blur(5px)'
	}
};
