import './src/css/style.css';
import './src/css/global.css';
require('prismjs/themes/prism-solarizedlight.css');

// Copied directly from https://prismic.io/docs/technologies/previews-gatsby
import * as React from 'react';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/prismicLinkResolver';
import BlogTemplate from './src/pages/blogTemplate';
import { Link } from 'gatsby';

export const wrapRootElement = ({ element }) => (
	<PrismicProvider
		linkResolver={linkResolver}
		//@ts-ignore
		internalLinkComponent={Link}
	>
		<PrismicPreviewProvider
			repositoryConfigs={[
				{
					repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
					linkResolver: doc => linkResolver(doc),
					componentResolver: componentResolverFromMap({
						blog_post: BlogTemplate
					})
				}
			]}
		>
			{element}
		</PrismicPreviewProvider>
	</PrismicProvider>
);
