import React from 'react';

import Header from './header';
import Footer from './Footer';

import 'typeface-open-sans';

function Layout({ children }) {
	return (
		<div className="leading-normal tracking-normal">
			{/* Navigation */}
			<Header />

			{children}

			{/* Footer */}
			<Footer />
		</div>
	);
}

export default Layout;
