import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { BlogLayout } from '../components/Blog/BlogLayout';

import { CtaSimple } from 'components/CTABlock';
import { BlogContent } from 'components/Blog/BlogContent';
import { BlogPost } from 'components/Blog/Blog';

export interface BlogTemplateProps {
	uid: BlogPost['uid'];
	title: BlogPost['data']['title'];
	subtitle: BlogPost['data']['subtitle'];
	displayDate: BlogPost['first_publication_date'];
	category: BlogPost['data']['category'];
	content: BlogPost['data']['body'];
	hero_image: BlogPost['data']['hero_image'];
}

// I had to move this file into the pages folder so that prismic previews would work (it required a page query, I think)

// function blogTemplate({ pageContext }: { pageContext: BlogTemplateProps }) {
function blogTemplate({ data }) {
	const prismicData = data.prismicBlogPost;
	const blogPost: BlogTemplateProps = {
		uid: prismicData.uid,
		title: prismicData.data.title,
		subtitle: prismicData.data.subtitle,
		displayDate: prismicData.first_publication_date,
		category: prismicData.data.category,
		content: prismicData.data.body,
		hero_image: {
			url: prismicData.data.hero_image.url,
			alt: prismicData.data.hero_image.alt,
			gatsbyImageData: prismicData.data.hero_image.gatsbyImageData
		}
	};
	return (
		<Layout>
			<SEO
				pageTitle={blogPost.title}
				description={blogPost.subtitle}
				imageUrl={blogPost.hero_image.url}
				imageAlt={blogPost.hero_image.alt}
				contentType="article"
			/>

			<BlogLayout currentCategory={blogPost.category}>
				<BlogContent blogPost={blogPost} />
			</BlogLayout>

			<CtaSimple eventPageName={`blog_${blogPost.uid}`} />
		</Layout>
	);
}

export const query = graphql`
	query BlogPost($uid: String) {
		prismicBlogPost(uid: { eq: $uid }) {
			_previewable
			uid
			first_publication_date(formatString: "MMMM DD, YYYY")
			data {
				title
				subtitle
				category
				hero_image {
					url
					alt
					gatsbyImageData(width: 780, placeholder: BLURRED)
				}
				body {
					... on PrismicBlogPostDataBodyRichText {
						id
						slice_label
						slice_type
						primary {
							rich_text {
								html
								richText
								text
							}
						}
					}
				}
			}
		}
	}
`;

//@ts-ignore
export default withPrismicPreview(blogTemplate);
