import React from 'react';
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconLookup } from '../utilities/iconLookup';
import { imageLookup } from '../utilities/imageLookup';

export const LogoBlock = ({ showLabel = true, size = 'm' }) => {
	return (
		<React.Fragment>
			<Link to={`/#`}>
				<div
					className="inline-flex text-center align-bottom border-none md:border-b-2"
					href="#"
				>
					<div className="flex justify-center w-10 h-10 p-1  ">
						{/* <div className="flex justify-center w-10 h-10 p-3 text-white duration-500 bg-gradient-to-bl from-red-500 to-red-400 rounded-xl transition-colors-shadow hover:from-red-600 hover:via-orange-500 hover:to-purple-500"> */}
						{/* <FontAwesomeIcon icon={iconLookup.faRocket} size="lg" /> */}
						<img src={imageLookup.Logo} alt="Embarkable Logo" />
					</div>
					{showLabel && (
						<div className="mx-2 my-2 text-2xl font-semibold leading-none text-gray-800 align-middle">
							Embarkable
						</div>
					)}
				</div>
			</Link>
		</React.Fragment>
	);
};

export const CopyrightBlock = ({ color = 'gray-600' }) => {
	return (
		<div className={`font-light text-${color}`}>
			<FontAwesomeIcon icon={iconLookup.faCopyright} size="sm" /> 2022 Embarkable
		</div>
	);
};
