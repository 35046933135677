//google analytics function
export function sendAnalyticsEvent(eventName) {
	if (isGtagReady()) {
		// config would be the 3rd parameter, but I'm omitting it for now for simplicity.
		window.gtag('event', eventName);
	}
	//process.env.GATSBY_PRODUCTION === 'true' && window.gtag('event', `subscribe_${event_id}`, { 'event_id': event_id, 'subscribe': result.success });
	//return (process.env.GATSBY_PRODUCTION === 'true' && typeof window !== 'undefined' && window.gtag('event', 'click', ` ${data} ` ));
}

//check if ok
export function isGtagReady() {
	return typeof window !== 'undefined' && window.gtag; // && process.env.GATSBY_PRODUCTION === 'true';
}
