import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { iconLookup } from '../../utilities/iconLookup';

import { SpinnerDiamond } from 'spinners-react';
import sendTrialSignup from './trialFunctions';
import { Button } from '../Button';
import { isGtagReady } from '../../utilities/analyticsFunctions';
// import sendSubscribe from '../../utilities/subscribeFunctions';

export const TrialSignupAxios = ({
	color = 'blue',
	inputText,
	buttonText,
	event_id,
	onSubscribe
}) => {
	const [success, setSuccess] = useState(false);
	const [setupComplete, setSetupComplete] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [view, setView] = useState(1);
	const [pwVisibility, setPwVisibility] = useState(false);

	//form values
	const [email, setEmail] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [teamName, setTeamName] = useState();

	console.log('errorMessage', errorMessage);

	const postEmail = async () => {
		sendTrialSignup('embarkable', email, firstName, lastName, password, teamName).then(
			result => {
				redirectTimer(result.success);
				setSuccess(result.success);
				setErrorMessage(result.message);
				subscribe(result.success);
				ga(result);
			}
		);
		//not used to close modal + onSubscribe(!result)))
	};

	//redirect link timer
	function redirectTimer(result) {
		if (result === true) {
			setTimeout(() => setSetupComplete(true), 2000);
		}
	}

	function ga(result) {
		isGtagReady() &&
			window.gtag('event', `subscribe_${event_id}`, {
				event_id: event_id,
				subscribe: result.success
			});
	}

	// function subscribe(result) {
	// 	console.log('subscribe', result);
	// 	if (result === true) {
	// 		sendSubscribe('embarkable', email, firstName, lastName, teamName);
	// 	}
	// }

	function inputChange(event) {
		//const target = event.target
		const name = event.target.name;
		const value = event.target.value;
		//console.log(value)
		switch (name) {
			case 'email':
				setEmail(value);
				break;
			case 'firstName':
				setFirstName(value);
				break;
			case 'lastName':
				setLastName(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'confirmPassword':
				setConfirmPassword(value);
				break;
			case 'teamName':
				setTeamName(value);
				break;
			default:
				break;
		}
	}

	const viewClass = `flex flex-col justify-center gap-3 my-8 lg:mx-auto lg:w-1/2`;
	const sectionClass = `flex flex-col w-full`;
	const rowClass = `flex flex-col lg:flex-row justify-center mx-auto w-full gap-3`;
	const labelClass = `flex w-full px-3 text-xs lg:text-xs text-white`;
	const inputClass = `p-2 m-1 text-sm w-full lg:text-md text-gray-800 bg-white border-none border-gray-200 rounded-md focus:outline-0 focus:bg-red-200`;

	return (
		<React.Fragment>
			{success ? (
				<div className="flex flex-col justify-center mt-16">
					<div className="text-4xl text-white textBlock">Welcome to Embarkable</div>

					<div className="flex flex-col justify-center mx-auto">
						{setupComplete ? (
							<React.Fragment>
								<div className="mt-16 mb-5 text-xl text-white textBlock">
									You're all set! Go to your new page now:
								</div>
								<Button
									link={`https://app.embarkable.io/`}
									isExternal
									isRound
									isLarge
									color="pink"
								>
									app.embarkable.io
								</Button>
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="mt-4 text-2xl text-white textBlock">
									Sit tight while we get your account setup
								</div>
								<div className="justify-center mx-auto my-16">
									<SpinnerDiamond
										size="100"
										color="white"
										secondaryColor="#ffffff38"
									/>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			) : (
				<div className="flex flex-col">
					<div className="flex flex-col justify-center mb-3">
						<div className="text-2xl text-white textBlock">
							Start your free trial now
						</div>
						<div className="text-sm text-white textBlock">No credit card required</div>
					</div>

					<div className={`${view !== 1 && 'hidden'}`}>
						<div className={viewClass}>
							<div className={rowClass}>
								<div className={sectionClass}>
									<div className={labelClass}>First name</div>
									<input
										className={inputClass}
										placeholder="First name"
										name="firstName"
										onChange={inputChange}
									/>
								</div>
								<div className={sectionClass}>
									<div className={labelClass}>Last name</div>
									<input
										className={inputClass}
										placeholder="Last name"
										name="lastName"
										onChange={inputChange}
									/>
								</div>
							</div>

							{/* <div className={alignClass}> */}
							<div className={sectionClass}>
								<div className={labelClass}>Team name</div>
								<input
									className={inputClass}
									placeholder="Company or product name"
									name="teamName"
									onChange={inputChange}
									autoComplete="off"
								/>
							</div>
							<div className={sectionClass}>
								<div className={labelClass}>Email</div>
								<input
									className={inputClass}
									placeholder="your@mail.com"
									type="email"
									name="email"
									onChange={inputChange}
								/>
							</div>

							{/* </div> */}

							<div className={sectionClass}>
								<div className={labelClass}>Password</div>
								<div className="flex flex-row justify-center w-full gap-3 m-1 bg-white rounded-md">
									<div className="w-full">
										<input
											className={inputClass}
											placeholder="Password"
											type={pwVisibility ? 'text' : 'password'}
											name="password"
											onChange={inputChange}
											autoComplete="off"
										/>
									</div>
									<EyeButton
										onClick={() => setPwVisibility(!pwVisibility)}
										icon={pwVisibility ? 'faEyeSlash' : 'faEye'}
									/>
								</div>
								{/*
								<div className={sectionClass}>
									<div className={labelClass}>confirm password</div>
									<input
										className={inputClass}
										placeholder="  confirm password"
										type="password"
										name="confirmPassword"
										onChange={inputChange}
										autoComplete="off"
									/>
								</div>*/}
							</div>
						</div>
					</div>

					<div className={`flex justify-center m-auto`}>
						<div className="flex flex-row gap-10 rounded-full bg-none">
							<SetupButton
								onClick={() => postEmail()}
								text="Start Now"
								icon="faChevronCircleRight"
							/>
						</div>
					</div>
					<div className={`flex my-4 mx-auto justify-center text-white`}>
						{errorMessage}
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

const BackButton = ({ viewId, text, icon, setView }) => {
	return (
		<button
			className="text-white rounded-full hover:text-purple-600 focus:outline-0"
			onClick={() => setView(viewId)}
			id="signup"
		>
			<FontAwesomeIcon icon={iconLookup[icon]} size="2x" />
		</button>
	);
};

const EyeButton = ({ onClick, disable = false, text, icon }) => {
	return (
		<button
			className={`px-2 py-2 m-1 text-sm font-bold text-pink-800
			 align-middle hover:bg-pink-200 hover:text-purple-600
			 rounded-md focus:outline-0`}
			onClick={onClick}
			disabled={disable}
		>
			<FontAwesomeIcon icon={iconLookup[icon]} size="lg" />
		</button>
	);
};

const SetupButton = ({ onClick, disable = false, text, icon }) => {
	return (
		<button
			className={`px-2 py-2 text-sm font-bold text-white uppercase align-middle ${
				disable ? 'bg-purple-400' : 'bg-purple-600 hover:bg-pink-200 hover:text-purple-600'
			} rounded-md  focus:outline-0`}
			onClick={onClick}
			disabled={disable}
			id="signup"
		>
			{text && <span className="mx-4">{text}</span>}
			<FontAwesomeIcon icon={iconLookup[icon]} size="lg" />
		</button>
	);
};
