import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

/*SEO guidelines
siteMetaData in gatsby-config
title: max 60 chars
description: max 160 chars
keywords: generally not used, but might be useful for something
image: important, shows up in web snippets
*/

// TODO: OG url

const siteName = 'Embarkable';
const defaultDescription =
	'Feel confident in your product by understanding what your users want. Centralize feedback, prioritize ideas, and craft a product roadmap.';
//put default image in static folder
const defaultImagePath = `images/embarkable-hero.png`;
const defaultImageAlt = `Embarkable: Centralize feedback, prioritize ideas, and craft a product roadmap.`;
const twitterUsername = '@EmbarkableApp';

function SEO({
	pageTitle = '',
	description,
	imageUrl,
	imageAlt,
	keywords = [],
	contentType = 'website',
	dontIndex = false
}: {
	pageTitle: string;
	description?: string;
	keywords?: string[];
	imageUrl?: string; // full url
	imageAlt?: string;
	contentType?: 'website' | 'article'; // https://ogp.me/#types
	dontIndex?: boolean;
}) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription = description || defaultDescription;
				const metaImage =
					imageUrl || data.site.siteMetadata.siteUrl + '/' + defaultImagePath;
				return (
					<Helmet
						htmlAttributes={{
							lang: 'en'
						}}
						title={pageTitle}
						titleTemplate={`%s | ${siteName}`}
						meta={[
							{ name: `title`, content: pageTitle },
							{ name: `description`, content: metaDescription },
							{ name: `image`, content: metaImage },
							{ name: `robots`, content: dontIndex ? 'noindex' : 'index, follow' },
							// Open graph (for facebook)
							{ property: `og:url`, content: pageTitle },
							{ property: `og:title`, content: pageTitle },
							{ property: `og:image`, content: metaImage },
							{ property: `og:image:alt`, content: imageAlt || defaultImageAlt },
							{ property: `og:description`, content: metaDescription },
							{ property: `og:site_name`, content: siteName },
							{ property: `og:type`, content: contentType },
							// Twitter
							{ property: `twitter:title`, content: pageTitle },
							{ property: `twitter:image`, content: metaImage },
							{ property: `twitter:description`, content: metaDescription },
							{ property: `twitter:card`, content: 'summary_large_image' }
						].concat(
							keywords.length > 0
								? {
										name: `keywords`,
										content: keywords.join(`, `)
								  }
								: []
						)}
					/>
				);
			}}
		/>
	);
}

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
