import Axios from 'axios';

//toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
	autoClose: 3000,
	draggable: false,
	position: toast.POSITION.BOTTOM_LEFT
});

export default async function sendTrialSignup(
	group,
	email,
	firstName,
	lastName,
	password,
	teamName
) {
	//config
	const instance = Axios.create({
		baseURL: '/.netlify/functions',
		headers: {}
	});

	// Send a POST request
	const result = await instance
		.post('trialSignup', {
			payload: {
				email: email,
				firstName: firstName,
				lastName: lastName,
				password: password,
				teamName: teamName
			}
		})
		.then(response => {
			//console.log('submit success');
			console.log('then', response.data);
			const success = response.data.status === 'success' ? true : false;
			// toast.success('Thanks for signing up!')
			if (success === false) {
				toast.warn(response.data.message);
			}
			return {
				success: success,
				message: response.data.message
			};
		})
		.catch(error => {
			// console.log('trial signup error', error.response);

			// console.log(error.response.data.message);
			var message = error.response.data.message;
			// console.log(message);
			if (!message || message === null) {
				message = 'An unexpected error occurred.';
			}

			toast.warn(message);
			return {
				success: false,
				message: message
			};
		});

	return result;
}
