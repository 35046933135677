import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ExternalLink } from 'components/Link';

export const RegisterFormFake = () => {
	const [isSubmitSuccess, setSubmitSuccess] = React.useState(false);
	const [isSubmitError, setSubmitError] = React.useState(false);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();

	const email = watch('email');
	const firstName = watch('first_name');
	const lastName = watch('last_name');

	const onSubmit = data => {
		// console.log(data);
		axios
			.post('/.netlify/functions/addSubscriberAI', {
				email: data.email,
				firstName: data.first_name,
				lastName: data.last_name
			})
			.then(function (response) {
				// handle success
				// console.log(response);
				setSubmitSuccess(true);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				setSubmitError(true);
			})
			.then(function () {
				// always executed
			});
	};

	const inputContainerClass = 'flex items-center border-2 mb-8 py-2 px-3 rounded-2xl';
	const inputClass = 'pl-2 w-full outline-none border-none';

	if (isSubmitSuccess) {
		return <AfterSignupSurvey email={email} firstName={firstName} lastName={lastName} />;
	}
	return (
		<form className="" onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-gray-800 font-bold text-2xl mb-1 md:pr-32">
				Sign Up for Embarkable
			</h1>
			<p className="text-sm font-normal text-gray-600 mb-8">Start your free trial now</p>
			<div className={inputContainerClass}>
				<input
					id="email"
					className={inputClass}
					type="email"
					name="email"
					placeholder="Email Address *"
					{...register('email', { required: true })}
				/>
			</div>
			<div className={inputContainerClass}>
				<input
					id="first_name"
					className={inputClass}
					type="first_name"
					name="first_name"
					placeholder="First Name *"
					{...register('first_name', { required: true })}
				/>
			</div>
			<div className={inputContainerClass}>
				<input
					id="last_name"
					className={inputClass}
					type="last_name"
					name="last_name"
					placeholder="Last Name *"
					{...register('last_name', { required: true })}
				/>
			</div>
			{/* <div className={inputContainerClass}>
				<input
					className={inputClass}
					type="password"
					name="password"
					id="password"
					placeholder="Password *"
					{...register('password')}
				/>
			</div> */}
			{Object.keys(errors).length > 0 && <span>Please fill in required fields.</span>}
			<button
				type="submit"
				className="block w-full mt-5 py-2 rounded-2xl bg-sky-400 hover:bg-sky-600 hover:shadow-xl transition-all duration-200 text-white font-semibold mb-2"
			>
				Sign Up
			</button>
			{isSubmitError && (
				<p className="mt-8">
					There was an error submitting your request. Please{' '}
					<ExternalLink colorVariant="primary" link="mailto:contact@embarkable.io">
						Contact Us
					</ExternalLink>{' '}
					and we'll get you set up!
				</p>
			)}
		</form>
	);
};

const AfterSignupSurvey = ({
	email,
	firstName,
	lastName
}: {
	email: string;
	firstName: string;
	lastName: string;
}) => {
	const [isSubmitSuccess, setSubmitSuccess] = React.useState(false);
	const [isSubmitError, setSubmitError] = React.useState(false);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();

	const support_service = watch('support_service');

	const onSubmit = data => {
		// console.log(data);
		axios
			.post('/.netlify/functions/submitFeedbackSurvey', {
				support:
					data.support_service === 'Other' ? data.support_input : data.support_service,
				numTickets: data.numTickets,
				otherSources: data.otherSources,
				comment: data.comment,
				email
			})
			.then(function (response) {
				// handle success
				// console.log(response);
				if (data.support_service === 'Zendesk') {
					location.href = `https://app.embarkable.io/split-signup?email=${encodeURIComponent(
						email
					)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(
						lastName
					)}`;
				} else {
					setSubmitSuccess(true); // Show thank you screen
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				setSubmitError(true);
			});
	};

	const inputContainerClass = 'flex items-center border-2 mb-8 py-2 px-3 rounded-2xl';
	const inputClass = 'pl-2 w-full outline-none border-none';
	const labelClass = 'px-1 mt-12 mb-1';
	const subtitleClass = 'px-1 mt-1 mb-2 text-xs';

	if (isSubmitSuccess) {
		return (
			<div className="text-center">
				<h2 className="mb-4 text-[80px]">🎉</h2>
				<h2 className="mb-2 font-semibold">Thank you!</h2>
				<p className="mb-12">
					We are currently in closed beta, but you've been signed up for the waitlist.
					You'll hear from us shortly!
				</p>
			</div>
		);
	}
	return (
		<form className="" onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-gray-800 font-bold text-2xl mb-1 md:pr-32">
				Just a few more questions...
			</h1>
			<div className={labelClass}>
				What service do you currently use for your email support?
			</div>

			<div className="flex items-center mb-4">
				<input
					id="support-option-1"
					type="radio"
					name="support_service"
					value="Help Scout"
					className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
					aria-labelledby="support-option-1"
					aria-describedby="support-option-1"
					{...register('support_service', { required: true })}
				/>
				<label
					htmlFor="support-option-1"
					className="text-sm font-medium text-gray-900 ml-2 block"
				>
					Help Scout
				</label>
			</div>

			<div className="flex items-center mb-4">
				<input
					id="support-option-2"
					type="radio"
					name="support_service"
					value="Groove"
					className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
					aria-labelledby="support-option-2"
					aria-describedby="support-option-2"
					{...register('support_service', { required: true })}
				/>
				<label
					htmlFor="support-option-2"
					className="text-sm font-medium text-gray-900 ml-2 block"
				>
					Groove
				</label>
			</div>

			<div className="flex items-center mb-4">
				<input
					id="support-option-zendesk"
					type="radio"
					name="support_service"
					value="Zendesk"
					className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
					aria-labelledby="support-option-zendesk"
					aria-describedby="support-option-zendesk"
					{...register('support_service', { required: true })}
				/>
				<label
					htmlFor="support-option-zendesk"
					className="text-sm font-medium text-gray-900 ml-2 block"
				>
					Zendesk
				</label>
			</div>

			<div className="flex items-center mb-4">
				<input
					id="support-option-other"
					type="radio"
					name="support_service"
					value="Other"
					className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
					aria-labelledby="support-option-other"
					aria-describedby="support-option-other"
					{...register('support_service', { required: true })}
				/>
				<label
					htmlFor="support-option-other"
					className="text-sm font-medium text-gray-900 ml-2 block"
				>
					Other (please specify)
				</label>
			</div>
			{support_service === 'Other' && (
				<div className={inputContainerClass}>
					<input
						id="support_input"
						className={inputClass}
						name="support_input"
						{...register('support_input', { required: true })}
					/>
				</div>
			)}
			<div className={labelClass}>
				Please provide an estimate of how many support messages you receive per month.
			</div>
			<div className={inputContainerClass}>
				<input
					id="numTickets"
					className={inputClass}
					name="numTickets"
					{...register('numTickets', { required: true })}
				/>
			</div>

			<div className={labelClass}>
				What other sources of feedback would you like to use Embarkable with?
			</div>
			<div className={subtitleClass}>
				For example, Slack, Intercom, Hubspot, Salesforce, Twitter, Capterra, Github
			</div>
			<div className={inputContainerClass}>
				<input
					id="otherSources"
					className={inputClass}
					name="otherSources"
					{...register('otherSources', { required: false })}
				/>
			</div>
			<div className={labelClass}>
				If you have any other comments for us, go ahead and let us know here!
			</div>
			<div className={inputContainerClass}>
				<input
					id="comment"
					className={inputClass}
					name="comment"
					{...register('otherSources', { required: false })}
				/>
			</div>
			{Object.keys(errors).length > 0 && <span>Please fill in required fields.</span>}
			<button
				type="submit"
				className="block w-full mt-5 py-2 rounded-2xl bg-sky-400 hover:bg-sky-600 hover:shadow-xl transition-all duration-200 text-white font-semibold mb-2"
			>
				Finish
			</button>
			{isSubmitError && (
				<p className="mt-8">
					There was an error submitting your request. Please{' '}
					<ExternalLink colorVariant="primary" link="mailto:contact@embarkable.io">
						Contact Us
					</ExternalLink>{' '}
					and we'll get you set up!
				</p>
			)}
		</form>
	);
};
