import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PrismicRichText, SliceZone } from '@prismicio/react';
import { BlogTemplateProps } from 'pages/blogTemplate';
import EmbarkableConnect from 'src/images/embarkable-connect.webp';
import { InternalLink } from 'components/Link';
import { registerUrl } from 'components/Register';

export const BlogContent = ({ blogPost }: { blogPost: BlogTemplateProps }) => {
	//@ts-ignore
	const image = getImage(blogPost.hero_image);
	return (
		<div className="markdown constrain-width-sm">
			<h1>{blogPost.title}</h1>
			<div className="pt-2">
				<div className="inline-block px-4 py-1 my-2 mr-4 text-xs text-white bg-blue-400 rounded-full">
					{blogPost.category}
				</div>
				{/* <div className="inline-block px-4 py-2 my-2 text-sm lg:mx-4">
					{blogPost.displayDate}
				</div> */}
				{/* <UserTag user={frontmatter.author} /> */}
			</div>
			<div className="w-full text-center mt-8 mb-16">
				<GatsbyImage image={image} alt={blogPost.hero_image.alt} />
			</div>
			{/* 
				//@ts-ignore */}
			<SliceZone slices={blogPost.content} components={components} />

			<h2>
				<InternalLink link="/">Embarkable:</InternalLink> Turn feedback into action
			</h2>
			<img
				className=""
				src={EmbarkableConnect}
				alt="Embarkable connects feedback to ideas so that you can build an informed roadmap."
			/>
			<p>
				<InternalLink link="/">Embarkable</InternalLink> is a product management platform
				that connects customer feedback to product goals to help you decide what to build,
				and why. It lets you consolidate feedback from many different sources so that you'll
				have more confidence in what to build, and a stronger understanding of your customer
				needs. Spend less time searching for the right insight, more time building the
				feature that makes the customer happy, and drive your business forward.
			</p>
			<p>
				In addition to building roadmaps and managing product launches, Embarkable allows
				you to monitor a single stream of incoming feedback to keep a pulse on what users
				are saying - something that would be much too time consuming to do manually. When
				you have different team members handling support or social media, Embarkable offers
				peace of mind because you'll know that critical feedback isn't falling through the
				cracks.
			</p>
			<p>
				<InternalLink link={registerUrl}>Try it for free.</InternalLink>
			</p>
		</div>
	);
};

const components = {
	rich_text: TextSlice
	// images: ImagesSlice
};
function TextSlice({ slice }) {
	// console.log(slice);
	return (
		<section>
			<PrismicRichText field={slice.primary.rich_text.richText} />
		</section>
	);
}
