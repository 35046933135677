import React from 'react';

import landingPageData from '../data/embarkablePageData';

import { ExternalLink, InternalLink } from './Link';
import { CopyrightBlock, LogoBlock } from './Logo';

export function Footer() {
	return (
		<footer className="bg-slate-100 py-12">
			<PageMap />

			<div className="flex flex-wrap justify-center px-4 mx-auto gap-4 xl:gap-10">
				<CopyrightBlock />
				<ExternalLink colorVariant="primary" link="mailto:contact@embarkable.io">
					Contact
				</ExternalLink>
				<InternalLink colorVariant="primary" link="/terms/" id="terms">
					Terms
				</InternalLink>
				<InternalLink colorVariant="primary" link="/privacy/" id="privacy">
					Privacy
				</InternalLink>
			</div>
		</footer>
	);
}

export default Footer;

export const PageMap = () => {
	return (
		<div className="constrain-width-lg flex justify-evenly gap-4 mb-8 px-8">
			<div className="hidden md:block -ml-8">
				<LogoBlock showLabel={true} size="lg" />
			</div>
			{/* 
			<div className="">
				<h6 className="text-xl text-black mb-2">Product</h6>
				{landingPageData[0].features.map((page, index) => {
					return (
						<div key={index}>
							<InternalLink link={`../#${page.id}`}>{page.name}</InternalLink>
						</div>
					);
				})}
			</div> */}

			{/* <GridContainer columns="2" style="p-1">
					<div className="text-xl text-black">Use Case</div>
					{landingPageData[0].useCase.map(page => {
						return (
							<LinkText  link="../#use-case">
								{page.title}
							</LinkText>
						);
					})}
				</GridContainer> */}

			<div>
				<h6 className="text-xl text-black mb-2">Resources</h6>
				{footerPageLinks.map((page, index) => {
					return (
						<div key={index}>
							<InternalLink link={`${page.link}`}>{page.name}</InternalLink>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const footerPageLinks = [
	// leading "/" makes the url relative to root, even if you are in a nested path currently.
	// trailing "/" avoids 301 redirects, which is bad for SEO
	{
		name: 'Help',
		link: '/docs/'
	},
	{
		name: 'Blog',
		link: '/blog/'
	},
	{
		name: 'Pricing',
		link: '/pricing/'
	},
	{
		name: 'Company',
		link: '/about/'
	}
];
