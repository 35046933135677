import React, { useState } from 'react';

import { ProductCard } from '../components/ProductCard';
import landingPageData from '../data/embarkablePageData';
import { useScrollPosition } from '../utilities/useScrollPosition';
import { LogoBlock } from './Logo';
import { RegisterButtonLink } from './Register';
import { ExternalLink, InternalLink } from './Link';

const solutionData = landingPageData[0].features;

function Header() {
	const [isExpanded, toggleExpansion] = useState(false);
	const [isFeatureExpanded, toggleFeatureExpansion] = useState(false);
	const [isResourcesExpanded, toggleResourcesExpansion] = useState(false);
	const [scroll, setScroll] = useState(0);
	const [scrollDown, setScrollDown] = useState(false);

	useScrollPosition(function setScrollPosition({ currentPosition }) {
		//console.log(scroll, currentPosition.y, scroll > currentPosition.y)
		//if (scroll > currentPosition.y){setScrollDown(true)}
		//else {setScrollDown(false)}
		setScroll(currentPosition.y);
	});

	return (
		<nav
			className={`${
				isFeatureExpanded || isResourcesExpanded || scroll < 0
					? `bg-white shadow-md transition-colors-shadow duration-500`
					: 'bg-transparent transition-colors-shadow duration-500'
			}
			fixed top-0 z-50 w-screen ${scrollDown && `hidden`}`}
		>
			<div
				className={`constrain-width-xl flex flex-wrap justify-between px-4 py-4 mt-0 ${
					isExpanded && 'bg-white'
				} `}
			>
				<div className="ml-4">
					<LogoBlock />
				</div>

				<button
					className="flex items-center px-3 py-2 text-gray-700 border border-white rounded md:hidden focus:outline-0"
					onClick={() => toggleExpansion(!isExpanded)}
				>
					<svg
						className="w-5 h-5 fill-current"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Menu</title>
						<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
					</svg>
				</button>
				{/*
				<div className={`${isExpanded ? `block` : `hidden`} w-full md:w-auto md:flex md:items-center`}>
					<div className="ml-4 text-sm" onClick={() => toggleExpansion(!isExpanded)}>

						<div
							onMouseOver = {() => {toggleFeatureExpansion(!isFeatureExpanded) & toggleResourcesExpansion(false)}}
							className={`${isFeatureExpanded ? `text-teal-400` : `text-gray-700`}
							block mt-4 mr-16 text-lg font-light cursor-pointer md:inline-block md:mt-0 hover:text-teal-400`}
						>
							Product
						</div>

						<Link
							to="/price"
							onMouseOver = {() => {toggleResourcesExpansion(false) & toggleFeatureExpansion(false)}}
							className="block mt-4 mr-16 text-lg font-light text-gray-700 no-underline cursor-pointer md:inline-block md:mt-0 hover:text-teal-400"
						>
							Pricing
						</Link>					

						<div
							onMouseOver = {() => {toggleResourcesExpansion(!isResourcesExpanded) & toggleFeatureExpansion(false)}}
							className={`${isResourcesExpanded ? `text-teal-400` : `text-gray-700`}
							block mt-4 mr-16 font-light text-lg cursor-pointer md:inline-block md:mt-0 hover:text-teal-400`}
						>
							Learn
						</div>

					</div>
				</div>
*/}
				<div
					className={`${isExpanded ? `block shadow-2xl` : `hidden`}
					w-full md:w-auto md:flex items-center`}
				>
					<div className="block my-6 text-center md:my-0 md:mr-6 md:inline-block">
						<InternalLink link="/blog/" isMedium>
							Blog
						</InternalLink>
					</div>
					<div className="block my-6 text-center md:my-0 md:mr-6 md:inline-block">
						<InternalLink link="/pricing/" isMedium>
							Pricing
						</InternalLink>
					</div>
					<div className="block my-6 text-center md:my-0 md:mr-6 md:inline-block">
						<ExternalLink link="https://app.embarkable.io/" isMedium>
							Login
						</ExternalLink>
					</div>
					<div className="block my-4 md:my-0 mx-6 md:ml-0 md:inline-block">
						<RegisterButtonLink
							isSmall
							label="Sign Up"
							eventLocation="navbar"
							isFullWidth
						/>
					</div>
				</div>
			</div>

			{isFeatureExpanded && (
				<div
					onClick={() => {
						toggleFeatureExpansion(!isFeatureExpanded);
					}}
					onMouseLeave={() => {
						toggleFeatureExpansion(!isFeatureExpanded);
					}}
					className={`flex-wrap justify-center w-full pb-10 lg:pt-10 border-t-2 mx-auto bg-white md:items-center md:w-auto`}
				>
					<div className="flex flex-wrap justify-center w-full mx-auto lg:w-3/4">
						<ProductCard data={solutionData} />
					</div>
					<div className="flex flex-wrap justify-center w-3/4 mx-auto">
						<InternalLink link="/product/" arrow isLarge>
							See all features
						</InternalLink>
					</div>
				</div>
			)}

			{isResourcesExpanded && (
				<div
					onClick={() => {
						toggleResourcesExpansion(!isResourcesExpanded);
					}}
					onMouseLeave={() => {
						toggleResourcesExpansion(!isResourcesExpanded);
					}}
					className="flex justify-center w-full pb-10 mx-auto bg-white border-t-2 lg:pt-10 md:items-center md:w-auto"
				>
					<div className="flex flex-wrap justify-center w-3/4 mx-auto">
						{/* <ProductCard data={learnData} /> */}
					</div>
				</div>
			)}
		</nav>
	);
}

export default Header;
