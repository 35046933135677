// /npm install --save @fortawesome/free-brands-svg-icons

//solid
import {
	faFileAlt,
	faCalculator,
	faPaperPlane,
	faChartLine,
	faBriefcase,
	faPercentage,
	faSwatchbook,
	faQuestionCircle,
	faInfoCircle,
	faCheck,
	faSync,
	faArrowCircleRight,
	faWaveSquare,
	faHistory,
	faSitemap,
	faHeadset,
	faCalendarAlt,
	faUsers,
	faComments,
	faMapPin,
	faInfinity,
	faBug,
	faBolt,
	faGlobe,
	faUserPlus,
	faRoute,
	faExpandAlt,
	faUser,
	faTimes,
	faRocket,
	faChevronCircleRight,
	faChevronCircleLeft,
	faChevronLeft,
	faChevronRight,
	faCircleNotch,
	faArrowRight,
	faEye,
	faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

//regular
import {
	faTimesCircle,
	faCopyright,
	faPlusSquare,
	faChartBar
} from '@fortawesome/free-regular-svg-icons';

export const iconLookup = {
	faFileAlt: faFileAlt,
	faCalculator: faCalculator,
	faPaperPlane: faPaperPlane,
	faChartLine: faChartLine,
	faBriefcase: faBriefcase,
	faQuestionCircle: faQuestionCircle,
	faInfoCircle: faInfoCircle,
	faCheck: faCheck,
	faSync: faSync,
	faArrowCircleRight: faArrowCircleRight,
	faHistory: faHistory,
	faSitemap: faSitemap,
	faHeadset: faHeadset,
	faCalendarAlt: faCalendarAlt,
	faUsers: faUsers,
	faUser: faUser,
	faComments: faComments,
	faBug: faBug,
	faBolt: faBolt,
	faGlobe: faGlobe,
	faUserPlus: faUserPlus,
	faRoute: faRoute,
	faExpandAlt: faExpandAlt,
	faTimes: faTimes,
	faTimesCircle: faTimesCircle,
	faRocket: faRocket,
	faCopyright: faCopyright,
	faChevronCircleRight: faChevronCircleRight,
	faChevronCircleLeft: faChevronCircleLeft,
	faChevronLeft: faChevronLeft,
	faChevronRight: faChevronRight,
	faArrowRight: faArrowRight,
	faCircleNotch: faCircleNotch,
	faPlusSquare: faPlusSquare,
	faChartBar: faChartBar,
	faPercentage: faPercentage,
	faWaveSquare: faWaveSquare,
	faMapPin: faMapPin,
	faSwatchbook: faSwatchbook,
	faInfinity: faInfinity,
	faEye: faEye,
	faEyeSlash: faEyeSlash
};
