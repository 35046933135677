//embark

import RoadmapNP from '../images/embark-roadmap-np.webp';

//mockup
import MockupValue from '../images/mockup-effort-high-low.webp';
import MockupWidget from '../images/mockup-widget.webp';
import MockupSwimlanes from '../images/mockup-swimlanes-objective.webp';
import MockupFeedback from '../images/mockup-feedback.webp';
import MockupFeatures from '../images/mockup-features.webp';

//gif
import HighlightFeedbackGIF from '../images/highlight-feedback-focus.webp';

//logo
import Logo from '../images/embarkable_logo-200.png';

//profile pics
import natelily from '../images/nate-lily-color.webp';

export const imageLookup = {
	RoadmapNP: RoadmapNP,

	MockupValue: MockupValue,
	MockupWidget: MockupWidget,
	MockupSwimlanes: MockupSwimlanes,
	MockupFeedback: MockupFeedback,
	MockupFeatures: MockupFeatures,

	HighlightFeedbackGIF: HighlightFeedbackGIF,

	Logo: Logo,
	natelily: natelily
};
