exports.linkResolver = doc => {
	// URL for a blog type
	if (doc?.type === 'blog_post') {
		//use a trailing slash to avoid 301 redirect
		return `/blog/${doc.uid}/`;
	}

	// URL for a product type
	// if (doc.type === 'product') {
	// 	return `/product/${doc.uid}`;
	// }

	// // URL for a page type
	// if (doc.type === 'page') {
	// 	return `/${doc.uid}`;
	// }

	// Backup for all other types
	return '/';
};
