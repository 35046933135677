import React from 'react';

import { RegisterButtonLink } from './Register';

// export function CtaSimple({ eventPageName }) {
// 	return (
// 		<div className={`relative w-full lg:py-28 overflow-hidden bg-gray-800`} id={'cta'}>
// 			<div className="absolute w-full transform skew-y-12 bg-blue-200 -mt-200 h-160" />
// 			<div className="absolute w-full h-56 mt-64 transform skew-y-12 bg-blue-300 xl:mt-128 xl:h-200" />
// 			<div className="absolute w-full h-56 transform skew-y-6 bg-blue-200 mt-80 xl:mt-128 xl:h-200" />

// 			<ParentGridContainer columns="1">
// 				<div className="w-full max-w-5xl mx-auto">
// 					<h2 className={`mb-2 text-blue-300`}>Work on what matters.</h2>
// 					<h1 className={`mb-16 text-white`}>Build better products.</h1>
// 					<RegisterButtonLink
// 						showCreditCardText
// 						label="Get Started"
// 						eventLocation={`${eventPageName}_bottomCTA`}
// 					/>
// 				</div>
// 			</ParentGridContainer>
// 		</div>
// 	);
// }

export function CtaSimple({ eventPageName }) {
	return (
		<div className="w-full h-160 bg-gradient-to-br from-teal-100 via-sky-200 to-purple-100">
			<div className="flex flex-col justify-center h-full px-8 constrain-width-md">
				<h2 className={`mb-4 text-sky-700 text-3xl`}>Unleash user insights.</h2>
				<h1 className={`mb-16 text-black`}>Build better products.</h1>
				<RegisterButtonLink
					showCreditCardText
					label="Get Started"
					eventLocation={`${eventPageName}_bottomCTA`}
				/>
			</div>
		</div>
	);
}
